import { defineAsyncComponent } from 'vue'
export default {
  "custom-header": defineAsyncComponent(() => import("/home/runner/work/checkup/checkup/apps/admin/src/layouts/customHeader.vue").then(m => m.default || m)),
  default: defineAsyncComponent(() => import("/home/runner/work/checkup/checkup/apps/admin/src/layouts/default.vue").then(m => m.default || m)),
  location: defineAsyncComponent(() => import("/home/runner/work/checkup/checkup/apps/admin/src/layouts/location.vue").then(m => m.default || m)),
  myself: defineAsyncComponent(() => import("/home/runner/work/checkup/checkup/apps/admin/src/layouts/myself.vue").then(m => m.default || m)),
  "organization-settings": defineAsyncComponent(() => import("/home/runner/work/checkup/checkup/apps/admin/src/layouts/organizationSettings.vue").then(m => m.default || m)),
  settings: defineAsyncComponent(() => import("/home/runner/work/checkup/checkup/apps/admin/src/layouts/settings.vue").then(m => m.default || m)),
  "simple-header": defineAsyncComponent(() => import("/home/runner/work/checkup/checkup/apps/admin/src/layouts/simpleHeader.vue").then(m => m.default || m)),
  single: defineAsyncComponent(() => import("/home/runner/work/checkup/checkup/apps/admin/src/layouts/single.vue").then(m => m.default || m))
}