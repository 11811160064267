import revive_payload_client_XBiWUEDVGfgNtXR0aYH51c7uGrW0zH0cvUNcBwCuiFA from "/home/runner/work/checkup/checkup/apps/admin/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__rj2kscpu46t57dpks5ljk3k45y/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_urGqmIzCmn3VVB9YesUJfwSaPOLSnBic1uGP15nAyFA from "/home/runner/work/checkup/checkup/apps/admin/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__rj2kscpu46t57dpks5ljk3k45y/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_AB0QnVnXUtG6kSTR9hkuqXJx3XN9IzLTwh_FDwmpRsY from "/home/runner/work/checkup/checkup/apps/admin/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__rj2kscpu46t57dpks5ljk3k45y/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_L8LSIzDKPpu4ENz9P7Nn7gDoOxsYgRuUf7KUXzr5kw8 from "/home/runner/work/checkup/checkup/apps/admin/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__rj2kscpu46t57dpks5ljk3k45y/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_OLpqznpwNAa_iHxRqua9ZdEkaupZtXYuMg8yRjaKg4A from "/home/runner/work/checkup/checkup/apps/admin/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__rj2kscpu46t57dpks5ljk3k45y/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_wYbHsS9wKOEzEKYIVmCs9SK0RyJzmXSnOMQ79TU7hQU from "/home/runner/work/checkup/checkup/apps/admin/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__rj2kscpu46t57dpks5ljk3k45y/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_LtaSNszJV4fjLpU__Xfo_vJ_LqwW2bSAS55b5qPnkns from "/home/runner/work/checkup/checkup/apps/admin/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__rj2kscpu46t57dpks5ljk3k45y/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/runner/work/checkup/checkup/apps/admin/.nuxt/components.plugin.mjs";
import prefetch_client_cFz9EkVw5AE2_7DBCjIvTR4vvA_GExVi1HtGAbm5OVs from "/home/runner/work/checkup/checkup/apps/admin/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.3.1_eslint@8.57.1_ioredis@5.6.0__rj2kscpu46t57dpks5ljk3k45y/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import supabase_client_NEJ48DWoeI8lDASvKn782WCwfPalC5RbeheBQ67ptr4 from "/home/runner/work/checkup/checkup/apps/admin/src/plugins/supabase.client.ts";
import sentry_client_alKR4qwfOmNOPbQ_WTISchX5XdIUEtwpyIuPf735J_4 from "/home/runner/work/checkup/checkup/apps/admin/src/plugins/sentry.client.ts";
import dayjs_client_2RrdPHczOxaDCvugYsIttcUY9i63cQtZx8ita4oqx_c from "/home/runner/work/checkup/checkup/apps/admin/src/plugins/dayjs.client.ts";
import gtag_ELQWoR9j7tYjh_ZQUMtNZsxypSJ9ljIjCD_GGaqRgcw from "/home/runner/work/checkup/checkup/apps/admin/src/plugins/gtag.ts";
import i18n_OkFQ9LTcb1ALD8at3v1fSiJbXuv91qvs85z9kOsJ3og from "/home/runner/work/checkup/checkup/apps/admin/src/plugins/i18n.ts";
import qrcode_client_pQrBRj3eARLqhLp_mxRy00b_OaEqUjig_Bt_OmwZFy4 from "/home/runner/work/checkup/checkup/apps/admin/src/plugins/qrcode.client.ts";
import vuedraggable_jYwokwvpqZX5Q5DZp6GPoVqUISwqvLWKqUTm_pNJ3bY from "/home/runner/work/checkup/checkup/apps/admin/src/plugins/vuedraggable.ts";
export default [
  revive_payload_client_XBiWUEDVGfgNtXR0aYH51c7uGrW0zH0cvUNcBwCuiFA,
  unhead_urGqmIzCmn3VVB9YesUJfwSaPOLSnBic1uGP15nAyFA,
  router_AB0QnVnXUtG6kSTR9hkuqXJx3XN9IzLTwh_FDwmpRsY,
  payload_client_L8LSIzDKPpu4ENz9P7Nn7gDoOxsYgRuUf7KUXzr5kw8,
  navigation_repaint_client_OLpqznpwNAa_iHxRqua9ZdEkaupZtXYuMg8yRjaKg4A,
  check_outdated_build_client_wYbHsS9wKOEzEKYIVmCs9SK0RyJzmXSnOMQ79TU7hQU,
  chunk_reload_client_LtaSNszJV4fjLpU__Xfo_vJ_LqwW2bSAS55b5qPnkns,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_cFz9EkVw5AE2_7DBCjIvTR4vvA_GExVi1HtGAbm5OVs,
  supabase_client_NEJ48DWoeI8lDASvKn782WCwfPalC5RbeheBQ67ptr4,
  sentry_client_alKR4qwfOmNOPbQ_WTISchX5XdIUEtwpyIuPf735J_4,
  dayjs_client_2RrdPHczOxaDCvugYsIttcUY9i63cQtZx8ita4oqx_c,
  gtag_ELQWoR9j7tYjh_ZQUMtNZsxypSJ9ljIjCD_GGaqRgcw,
  i18n_OkFQ9LTcb1ALD8at3v1fSiJbXuv91qvs85z9kOsJ3og,
  qrcode_client_pQrBRj3eARLqhLp_mxRy00b_OaEqUjig_Bt_OmwZFy4,
  vuedraggable_jYwokwvpqZX5Q5DZp6GPoVqUISwqvLWKqUTm_pNJ3bY
]