
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexltbOfmcXj4cf_E9QjSjFaJgEeLSU2mW7WdweGUgtqvoMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/index.vue?macro=true";
import { default as login_A6TcI_45qbowgkjBokLiPbtYF3p46niXNizF3mkR002MMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/login.vue?macro=true";
import { default as editSWm0SRb_452Fa6p_45ab5NVYHA7weBB2bL64btJ9oJ2cal4Meta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/edit.vue?macro=true";
import { default as indexgpD5iRTrJf9p7YxeO73vK79VWYyO8cl_RD3DiS5Ew4gMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/index.vue?macro=true";
import { default as editt_aR4f102OttmiYu70jTiFJcalDin1k85yQKnNCHpnIMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/myself/edit.vue?macro=true";
import { default as indexbbhewgb3RT7RSWYn_MKe_L6IE1ZM0KNp11fimFO74FgMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/users/index.vue?macro=true";
import { default as setupzPORGKXUDFhh4aXLxl6ir2oDZWDTkWFQUZ45rP_DodUMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/myself/setup.vue?macro=true";
import { default as indextYQsk9n9jxaNxA_454YgrnT8NoA240bD_LqPejahDT5MUMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/index.vue?macro=true";
import { default as passwordEditYOfnqej5Crnwnmu03d_FFTgNQfeIvdIHnj_0n6Q4USIMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/myself/passwordEdit.vue?macro=true";
import { default as indexHkMtxKGBIAqkjhJkul1HhPE0Pn_jDKR2B590dWNL_454oMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/images/[organizationId]/[objectId]/index.vue?macro=true";
import { default as editwEpjbV_458aTZ7oJqnZ9SQSG9Q0zXuivnzB9R4FCdpmdoMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/edit.vue?macro=true";
import { default as index56hSUD_45mNVuXHrFqsVgqd5Gf_453Q44iaYFP_45T7n6wl6MMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/users/index.vue?macro=true";
import { default as indexaDLdntlZdPR7Cncw_kwepJSPJ0Sb2u_45_457mpxdNr9FogMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/myPage/index.vue?macro=true";
import { default as indexBAYUtQKU2syPmNzuxhWhBp3hFoAvEGU6tKywtkvbPngMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/tickets/index.vue?macro=true";
import { default as indexTjh7cPrXD_y0Ige9DYnUMQRri_nUGSOKJU65H1_I8OYMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/accounts/index.vue?macro=true";
import { default as indexioPbd_V2vq_45MPSYmDXZyAqYrjb7a6OmQ52_45Z4Kbv7fQMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/timeboxes/index.vue?macro=true";
import { default as index9ZNgb9HJnmif5i50PMfoQgQyVKE_45lFpn_HNgoaMpMJUMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/checkFiles/index.vue?macro=true";
import { default as indexG5UfahAktoEoRy49hsvf_45a8wNXuCIDNlbzB30J3lpXYMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/checkSheets/index.vue?macro=true";
import { default as indexerXKOiOnMcnelqDp4BMpXAktdyh18olhV5R4pJzyqmUMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/ancillaryWorks/index.vue?macro=true";
import { default as indexUhPGfRa5lRqLt5GwMJFdzCaHQ4jdNbAxzitEusU74S0Meta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/myPage/tickets/index.vue?macro=true";
import { default as indexPzqBNRWBhYyqZ3RsKT2KiNUs18QKKQg928nFq0zUNwoMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/noteCategories/index.vue?macro=true";
import { default as indexjHEdGESjgHkjVTYqsSu3F1386G_45qhy6Svzb2mMpqqQkMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/checkFileLabels/index.vue?macro=true";
import { default as indexgr8yD3B1o6KLqSA2gFRU3hicSDMp5FIwINqStY_eQ6wMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/checkSheetLabels/index.vue?macro=true";
import { default as indexX6gJEEaX0Or4M8hkBWJbGoSgrnr6fd_qBZnr2qo1hhwMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/monthlyWorkFiles/index.vue?macro=true";
import { default as indexRt_9eweCfJI7b80fS_45spLi0OOFf6_45cd8mLOw6LzHHuoMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/tickets/[ticketId]/index.vue?macro=true";
import { default as indexqbx3Jf5_196WoS_Rsiqzm5m4yEnWepBCEmrtqusBTBgMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/checkFiles/[checkFileId]/index.vue?macro=true";
import { default as indexvteeOLkZyegrUh1YwQYUn2k93Jeay9ZLl6i9sZT0mZsMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/myPage/dailyVerifications/index.vue?macro=true";
import { default as index_45uGWLd3pb7szOFG393H_45oFyR46dllVwM6bRnU1_45TdLIMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/checkSheets/[checkSheetId]/index.vue?macro=true";
import { default as indexjUeKtc7fUn_45Mym_453JVnrRBqCgnQJRDcfo4PDgd5UQ3oMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/myPage/monthlyVerifications/index.vue?macro=true";
import { default as indexK9u9leP80vXrPAByyGk460ulX_45r3hX8IEjwBVJB6tmAMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/myPage/dailyVerifications/[workFileId]/index.vue?macro=true";
import { default as indexw9GcVgDvWrOTnaULNEaf1H2QFXCsV0VZXvpzckGULsAMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/monthlyWorkFiles/[checkFileId]/[yyyy]/[mm]/index.vue?macro=true";
import { default as indexMFUEFbUyUUWS2n7NgnJBpdC1l_Jtqgn_45gWO_456r1aRboMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/myPage/monthlyVerifications/[monthlyWorkFileId]/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login_A6TcI_45qbowgkjBokLiPbtYF3p46niXNizF3mkR002MMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/login.vue")
  },
  {
    name: "organizationId-edit",
    path: "/:organizationId()/edit",
    meta: editSWm0SRb_452Fa6p_45ab5NVYHA7weBB2bL64btJ9oJ2cal4Meta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/edit.vue")
  },
  {
    name: "organizationId",
    path: "/:organizationId()",
    meta: indexgpD5iRTrJf9p7YxeO73vK79VWYyO8cl_RD3DiS5Ew4gMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/index.vue")
  },
  {
    name: "organizationId-myself-edit",
    path: "/:organizationId()/myself/edit",
    meta: editt_aR4f102OttmiYu70jTiFJcalDin1k85yQKnNCHpnIMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/myself/edit.vue")
  },
  {
    name: "organizationId-users",
    path: "/:organizationId()/users",
    meta: indexbbhewgb3RT7RSWYn_MKe_L6IE1ZM0KNp11fimFO74FgMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/users/index.vue")
  },
  {
    name: "organizationId-myself-setup",
    path: "/:organizationId()/myself/setup",
    meta: setupzPORGKXUDFhh4aXLxl6ir2oDZWDTkWFQUZ45rP_DodUMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/myself/setup.vue")
  },
  {
    name: "organizationId-locations",
    path: "/:organizationId()/locations",
    meta: indextYQsk9n9jxaNxA_454YgrnT8NoA240bD_LqPejahDT5MUMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/index.vue")
  },
  {
    name: "organizationId-myself-passwordEdit",
    path: "/:organizationId()/myself/passwordEdit",
    meta: passwordEditYOfnqej5Crnwnmu03d_FFTgNQfeIvdIHnj_0n6Q4USIMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/myself/passwordEdit.vue")
  },
  {
    name: "images-organizationId-objectId",
    path: "/images/:organizationId()/:objectId()",
    meta: indexHkMtxKGBIAqkjhJkul1HhPE0Pn_jDKR2B590dWNL_454oMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/images/[organizationId]/[objectId]/index.vue")
  },
  {
    name: "organizationId-locations-locationId-edit",
    path: "/:organizationId()/locations/:locationId()/edit",
    meta: editwEpjbV_458aTZ7oJqnZ9SQSG9Q0zXuivnzB9R4FCdpmdoMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/edit.vue")
  },
  {
    name: "organizationId-locations-locationId-users",
    path: "/:organizationId()/locations/:locationId()/users",
    meta: index56hSUD_45mNVuXHrFqsVgqd5Gf_453Q44iaYFP_45T7n6wl6MMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/users/index.vue")
  },
  {
    name: "organizationId-locations-locationId-myPage",
    path: "/:organizationId()/locations/:locationId()/myPage",
    meta: indexaDLdntlZdPR7Cncw_kwepJSPJ0Sb2u_45_457mpxdNr9FogMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/myPage/index.vue")
  },
  {
    name: "organizationId-locations-locationId-tickets",
    path: "/:organizationId()/locations/:locationId()/tickets",
    meta: indexBAYUtQKU2syPmNzuxhWhBp3hFoAvEGU6tKywtkvbPngMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/tickets/index.vue")
  },
  {
    name: "organizationId-locations-locationId-accounts",
    path: "/:organizationId()/locations/:locationId()/accounts",
    meta: indexTjh7cPrXD_y0Ige9DYnUMQRri_nUGSOKJU65H1_I8OYMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/accounts/index.vue")
  },
  {
    name: "organizationId-locations-locationId-timeboxes",
    path: "/:organizationId()/locations/:locationId()/timeboxes",
    meta: indexioPbd_V2vq_45MPSYmDXZyAqYrjb7a6OmQ52_45Z4Kbv7fQMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/timeboxes/index.vue")
  },
  {
    name: "organizationId-locations-locationId-checkFiles",
    path: "/:organizationId()/locations/:locationId()/checkFiles",
    meta: index9ZNgb9HJnmif5i50PMfoQgQyVKE_45lFpn_HNgoaMpMJUMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/checkFiles/index.vue")
  },
  {
    name: "organizationId-locations-locationId-checkSheets",
    path: "/:organizationId()/locations/:locationId()/checkSheets",
    meta: indexG5UfahAktoEoRy49hsvf_45a8wNXuCIDNlbzB30J3lpXYMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/checkSheets/index.vue")
  },
  {
    name: "organizationId-locations-locationId-ancillaryWorks",
    path: "/:organizationId()/locations/:locationId()/ancillaryWorks",
    meta: indexerXKOiOnMcnelqDp4BMpXAktdyh18olhV5R4pJzyqmUMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/ancillaryWorks/index.vue")
  },
  {
    name: "organizationId-locations-locationId-myPage-tickets",
    path: "/:organizationId()/locations/:locationId()/myPage/tickets",
    meta: indexUhPGfRa5lRqLt5GwMJFdzCaHQ4jdNbAxzitEusU74S0Meta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/myPage/tickets/index.vue")
  },
  {
    name: "organizationId-locations-locationId-noteCategories",
    path: "/:organizationId()/locations/:locationId()/noteCategories",
    meta: indexPzqBNRWBhYyqZ3RsKT2KiNUs18QKKQg928nFq0zUNwoMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/noteCategories/index.vue")
  },
  {
    name: "organizationId-locations-locationId-checkFileLabels",
    path: "/:organizationId()/locations/:locationId()/checkFileLabels",
    meta: indexjHEdGESjgHkjVTYqsSu3F1386G_45qhy6Svzb2mMpqqQkMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/checkFileLabels/index.vue")
  },
  {
    name: "organizationId-locations-locationId-checkSheetLabels",
    path: "/:organizationId()/locations/:locationId()/checkSheetLabels",
    meta: indexgr8yD3B1o6KLqSA2gFRU3hicSDMp5FIwINqStY_eQ6wMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/checkSheetLabels/index.vue")
  },
  {
    name: "organizationId-locations-locationId-monthlyWorkFiles",
    path: "/:organizationId()/locations/:locationId()/monthlyWorkFiles",
    meta: indexX6gJEEaX0Or4M8hkBWJbGoSgrnr6fd_qBZnr2qo1hhwMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/monthlyWorkFiles/index.vue")
  },
  {
    name: "organizationId-locations-locationId-tickets-ticketId",
    path: "/:organizationId()/locations/:locationId()/tickets/:ticketId()",
    meta: indexRt_9eweCfJI7b80fS_45spLi0OOFf6_45cd8mLOw6LzHHuoMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/tickets/[ticketId]/index.vue")
  },
  {
    name: "organizationId-locations-locationId-checkFiles-checkFileId",
    path: "/:organizationId()/locations/:locationId()/checkFiles/:checkFileId()",
    meta: indexqbx3Jf5_196WoS_Rsiqzm5m4yEnWepBCEmrtqusBTBgMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/checkFiles/[checkFileId]/index.vue")
  },
  {
    name: "organizationId-locations-locationId-myPage-dailyVerifications",
    path: "/:organizationId()/locations/:locationId()/myPage/dailyVerifications",
    meta: indexvteeOLkZyegrUh1YwQYUn2k93Jeay9ZLl6i9sZT0mZsMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/myPage/dailyVerifications/index.vue")
  },
  {
    name: "organizationId-locations-locationId-checkSheets-checkSheetId",
    path: "/:organizationId()/locations/:locationId()/checkSheets/:checkSheetId()",
    meta: index_45uGWLd3pb7szOFG393H_45oFyR46dllVwM6bRnU1_45TdLIMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/checkSheets/[checkSheetId]/index.vue")
  },
  {
    name: "organizationId-locations-locationId-myPage-monthlyVerifications",
    path: "/:organizationId()/locations/:locationId()/myPage/monthlyVerifications",
    meta: indexjUeKtc7fUn_45Mym_453JVnrRBqCgnQJRDcfo4PDgd5UQ3oMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/myPage/monthlyVerifications/index.vue")
  },
  {
    name: "organizationId-locations-locationId-myPage-dailyVerifications-workFileId",
    path: "/:organizationId()/locations/:locationId()/myPage/dailyVerifications/:workFileId()",
    meta: indexK9u9leP80vXrPAByyGk460ulX_45r3hX8IEjwBVJB6tmAMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/myPage/dailyVerifications/[workFileId]/index.vue")
  },
  {
    name: "organizationId-locations-locationId-monthlyWorkFiles-checkFileId-yyyy-mm",
    path: "/:organizationId()/locations/:locationId()/monthlyWorkFiles/:checkFileId()/:yyyy()/:mm()",
    meta: indexw9GcVgDvWrOTnaULNEaf1H2QFXCsV0VZXvpzckGULsAMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/monthlyWorkFiles/[checkFileId]/[yyyy]/[mm]/index.vue")
  },
  {
    name: "organizationId-locations-locationId-myPage-monthlyVerifications-monthlyWorkFileId",
    path: "/:organizationId()/locations/:locationId()/myPage/monthlyVerifications/:monthlyWorkFileId()",
    meta: indexMFUEFbUyUUWS2n7NgnJBpdC1l_Jtqgn_45gWO_456r1aRboMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/myPage/monthlyVerifications/[monthlyWorkFileId]/index.vue")
  }
]